.applicationCard {
    border-radius: 2vmin;
    box-shadow: 3px 5px 10px 1px rgba(130, 130, 130, .3);
    margin: 3vmin 0;
}
.applicationCard:last-child {
    margin-bottom: 6vmin;
}
.applicationCard:first-child {
    margin-top: 6vmin;
}

.applicationCardContent {
    padding-top: 0.1vmin;
    padding-left: 3vmin;
    padding-right: 3vmin;
    padding-bottom: 3vmin
}
