.companyCard {
    border-radius: 2vmin;
    box-shadow: 3px 5px 10px 1px rgba(130, 130, 130, .3);
    margin: 3vmin 0;
}
.companyCard:last-child {
    margin-bottom: 6vmin;
}
.companyCard:first-child {
    margin-top: 6vmin;
}
