:root {
    /*
        Color palette
        default color: https://material.io/design/material-theming/implementing-your-theme.html#color
    */
    --default-purple: #6200ee;
    --default-purple-variant: #3700b3;
    --default-light-blue: #03dac6;

    --appl-tracky-purple: #5D1049;
    --appl-tracky-purple-variant: #8d3f75;
    --appl-tracky-peach: #fa3336;

    /* implementing your theme (doc): https://material.io/design/material-theming/implementing-your-theme.html#color */
    /* mdc theme: https://github.com/material-components/material-components-web/tree/master/packages/mdc-theme */
    --mdc-theme-primary: var(--appl-tracky-purple);
    --mdc-theme-primary-variant: var(--appl-tracky-purple-variant); /* color variants can also be used to complement and provide accessible options for your primary and secondary colors. */
    --mdc-theme-secondary: var(--appl-tracky-peach); /* Secondary colors are most often used as accents on components, such as FABS and selection controls. */
    --mdc-theme-background: white; /* found behind scrollable content */
    --mdc-theme-surface: white; /* components such as cards, sheets, and menus */
    --mdc-theme-error: #b00020; /* indicates errors in components, such as text fields */

    
    /*
        “On” colors
        The elements in an app use colors from specific categories in your color palette, such as a primary color. Whenever other screen elements, such as text or icons, appear in front of surfaces using those colors, those elements should use colors specifically designed to appear clearly and legibly against the colors behind them.

        This category of colors is called “on” colors, referring to the fact that they color elements that are sometimes placed “on” top of key surfaces that use a primary color, secondary color, surface color, background color, or error color. These are labelled using the original category name (such as primary color) with the prefix “on”.

        “On” colors are primarily applied to text, iconography, and strokes. Sometimes, they are also applied to surfaces.
    */
    --mdc-theme-on-primary: white;
    --mdc-theme--text-primary-on-dark: white;

    --mdc-theme-on-secondary: black;
    --mdc-theme--text-primary-on-light: black;

    --mdc-theme-on-background: black;
    --mdc-theme-on-surface: black;
    --mdc-theme-on-error: white;

    
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button.mdc-button {
    border-radius: 15px;
}

button.mdc-button.mdc-theme-secondary {
    background-color: var(--mdc-theme-secondary);
    color: var(--mdc-theme-on-secondary);
}