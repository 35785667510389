:root {
    --page-transition-duration: 500ms;
}

.exit {
    position: absolute;

    opacity: 1;
    transform: scale(1);

    will-change: opacity transform;
}

.exitActive, .exitDone {
    position: absolute;

    opacity: 0;
    transform: scale(0.9);
    
    transition: opacity var(--page-transition-duration), transform var(--page-transition-duration);
    will-change: opacity transform;
}

.enter {
    position: absolute;

    opacity: 0;
    transform: scale(1.1);

    will-change: opacity transform;
}

.enterActive, .enterDone {
    position: absolute;
    width: 100%;

    opacity: 1;
    transform: scale(1);

    transition: opacity var(--page-transition-duration), transform var(--page-transition-duration);
    will-change: opacity transform;
}