.LandingPage {
    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;
    
    padding-top: 10vmin;
    padding-bottom: 15vmin;

background: linear-gradient(40deg,rgba(247,235,255,1) 0%, rgba(225,166,246,1) 100%);
}

.primaryPageContent {
    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: 70vw;
}

.headlineContent {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.headlineText {
    /* font-size: calc(20px + 3vmin); */
    font-weight: 500;
}

.headlineSubText {

}

.loginActionsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: black;

    margin: 30px 0;
}

.sideNoteContent {
    display: flex;
    flex-direction: column;
    align-items: center;
}