.UserAppPageHeader {
    background-color: var(--mdc-theme-primary);
    color: var(--mdc-theme--text-primary-on-dark);
    padding: 5px 0px 20px 5px;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.companyListHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.searchField {
    margin-top: 3vmin;
    width: 70vmin;
}
